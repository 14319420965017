import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faCalendarDays, faRulerCombined, faUsers, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { faBars, faTimes, faCheck, faPhone, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTwitter,
	faWhatsapp,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faStar, faStarHalf } from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faCalendarDays,
	faBars,
	faTimes,
	faPhone,
	faEnvelope,
	faCheck,
	faChevronUp,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faFacebook,
	faInstagram,
	faWhatsapp,
	faYoutube,
	faLinkedin,
	faTwitter,
	faStar,
	faStarHalf,
	faRulerCombined,
	faUsers,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
